import React, { useState } from "react";

import styled from "@emotion/styled";

import { apiDownloadProtected } from "../../../../../apiBindings";
import { toReadableDateTime } from "../../../../../utils/DateHelpers";
import NewSelect from "../../../../shared/forms/NewSelect";
import Button from "../../../../shared/NewForms/Button";
import NewHeadline from "../../../../shared/NewHeadline";
import useStore from "../../Dashboard/store";
import Box from "../Box";
import AdBluePriceTable from "./AdBluePriceTable";

const SpaceBetween = styled.div`
    display: flex;
    gap: 25px;
    justify-content: space-between;
    margin-top: 10px;
    flex-direction: column;

    ${({ theme }) => theme.breakpoints.from.medium.css`
        flex-direction: row;
    `}
`;

const UpdatedAtText = styled.div`
    text-align: center;
`;

const Disclaimer = styled.div`
    padding-top: 10px;

    > span {
        font-weight: bold;
    }
`;

type SelectableMonths = "currentMonth" | "lastMonth";
type SelectableStates = {
    currentMonth: "currentMonth";
    lastMonth: "lastMonth";
};

const selected: SelectableStates = {
    currentMonth: "currentMonth",
    lastMonth: "lastMonth",
};

const options = [
    {
        value: "currentMonth",
        text: "Aktueller Monat",
    },
    {
        value: "lastMonth",
        text: "Letzter Monat",
    },
];

const getUpdatedAtText = (date: string): string => {
    const formattedDate = toReadableDateTime(date, true);
    return `Letzte Aktualisierung: ${formattedDate} Uhr`;
};

const AdBluePriceList = ({ ...props }) => {
    const [getSelectedCustomer] = useStore((state) => [
        state.getSelectedCustomer,
    ]);
    const selectedCustomer = getSelectedCustomer();
    const [selectedMonth, setSelectedMonth] = useState<SelectableMonths>(
        selected.currentMonth
    );

    const download = () => {
        apiDownloadProtected(`/hoyercard-portal/dashboard/adBlueDownload`)
            .then((res) => res.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "rechnungsbeileger.pdf";
                a.click();
                a.remove();
                setTimeout(() => window.URL.revokeObjectURL(url), 100);
            });
    };

    if (!selectedCustomer || !selectedCustomer.adBluePrices) {
        return (
            <div {...props}>
                <Box>
                    <p>Es konnten keine AdBlue® Preisdaten geladen werden.</p>
                </Box>
            </div>
        );
    }

    return (
        <div {...props}>
            <Box>
                <NewHeadline>
                    Hoyer Card AdBlue® Preis Deutschland (netto) in Euro/Liter*
                </NewHeadline>
                <SpaceBetween>
                    <div>
                        <NewSelect
                            name="selectedMonth"
                            placeholder="Zeitraum auswählen"
                            onChange={(
                                e: React.ChangeEvent<HTMLSelectElement>
                            ) =>
                                setSelectedMonth(
                                    e.target.value as SelectableMonths
                                )
                            }
                            options={options}
                            value={selectedMonth}
                            minWidth={undefined}
                        ></NewSelect>
                    </div>

                    <div>
                        <Button small color="redOutline" onClick={download}>
                            Rechnungsbeileger herunterladen
                        </Button>
                        <UpdatedAtText>
                            {getUpdatedAtText(
                                selectedCustomer?.adBluePrices.updatedAt
                            )}
                        </UpdatedAtText>
                    </div>
                </SpaceBetween>
                <AdBluePriceTable
                    data={selectedCustomer?.adBluePrices[selectedMonth]}
                />
                <Disclaimer>
                    <span>*Disclaimer - Haftungsausschluss </span> <br />
                    1. Gültig an allen Hoyer Tankstellen sowie an teilnehmenden
                    Tankstellen mit Akzeptanz der Hoyer Card.
                    <br />
                    2. Alle Angaben innerhalb dieser Preisliste sind ohne
                    Gewähr.
                    <br />
                    3. Alle hier aufgeführten Preise sind Nettopreise exkl.
                    Mehrwertsteuer.
                </Disclaimer>
            </Box>
        </div>
    );
};

export default AdBluePriceList;
