import React from "react";

import { Permission, useHoyerAcl } from "../../../../hooks/useHoyerAcl";
import HoyerAclError from "../../../shared/HoyerAclError";
import Container from "../components/Container";
import RedeemCodeRequester from "../components/RedeemCodeRequester";

const RequestActivation = () => {
    const { can } = useHoyerAcl();

    if (!can(Permission.USER_REQUEST_ACTIVATION)) {
        return (
            <HoyerAclError message="Du hast keine Berechtigung einen Aktivierungscode zu beantragen." />
        );
    }

    return (
        <Container>
            <RedeemCodeRequester />
        </Container>
    );
};

export default RequestActivation;
