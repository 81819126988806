import React, { FC, useEffect } from "react";

import Helmet from "react-helmet";

export type DevextremeStylesProps = Record<never, any>;

const DevextremeStyles: FC<DevextremeStylesProps> = () => (
    <Helmet>
        <link rel="stylesheet" href="/styles/devextreme/dx.light.css" />
    </Helmet>
);

export default DevextremeStyles;
