import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Container = styled.div`
    ${({ theme }) => css`
        ${theme.paddings.containerOuter.left}
        ${theme.paddings.containerOuter.right}
        ${theme.paddings.containerOuter.bottom}

        color: ${theme.colors.blue.toString()};
        padding-top: 20px;
    `}
`;

export default Container;
