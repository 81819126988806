import React from "react";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import NewHeadline from "../../../../../shared/NewHeadline";
import Box from "../../../components/Box";
import UnlockForm, { types } from "../components/UnlockForm";

const GenerateCode = () => {
    const { can } = useHoyerAcl();
    if (!can(Permission.USER_GENERATE_ACTIVATION_CODE)) {
        return null;
    }
    return (
        <Box>
            <NewHeadline>Aktivierungscode generieren</NewHeadline>
            <p>Für die Kundennummer wird ein Aktivierungscode generiert.</p>
            <UnlockForm type={types.generateCode} />
        </Box>
    );
};

export default GenerateCode;
