import React from "react";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import NewHeadline from "../../../../../shared/NewHeadline";
import Box from "../../../components/Box";
import UnlockForm, { types } from "../components/UnlockForm";

const UnlockDirectly = () => {
    const { can } = useHoyerAcl();
    if (!can(Permission.USER_CONNECT_TO_CUSTOMER)) {
        return null;
    }
    return (
        <Box>
            <NewHeadline>Kundennummer verknüpfen</NewHeadline>
            <p>
                Die Kundennummer wird direkt für den Benutzer mit der eingegeben
                E-Mail Adresse verknüpft.
            </p>
            <UnlockForm type={types.unlockDirectly} />
        </Box>
    );
};

export default UnlockDirectly;
