import React from "react";

import Columns from "../../../../shared/Columns";
import Headline from "../../../../shared/Headline";
import Box from "../Box";

const CustomerData = ({ data, services, ...props }) => {
    return (
        <div {...props}>
            <Box>
                <Columns>
                    <Columns.Column
                        mobile="full"
                        tablet="half"
                        desktop="one-third"
                    >
                        <Headline>Kundendaten</Headline>
                        {data === null ? (
                            "Keine Daten verfügbar"
                        ) : (
                            <div>
                                {data.salutation} {data.firstname}{" "}
                                {data.lastname}
                                <br />
                                {data.additional_name !== "" && (
                                    <>
                                        {data.additional_name}
                                        <br />
                                    </>
                                )}
                                {data.owner !== "" && (
                                    <>
                                        {data.owner}
                                        <br />
                                    </>
                                )}
                                {data.street !== "" && (
                                    <>
                                        {data.street}
                                        <br />
                                    </>
                                )}
                                {data.zipcode !== "" && data.city !== "" && (
                                    <>
                                        {data.zipcode} {data.city}
                                        <br />
                                    </>
                                )}
                                {data.phone !== "" && (
                                    <>
                                        <br />
                                        Tel.: {data.phone}
                                        <br />
                                    </>
                                )}
                            </div>
                        )}
                    </Columns.Column>
                    <Columns.Column
                        mobile="full"
                        tablet="half"
                        desktop="one-third"
                    >
                        <Headline>Gebuchte Services</Headline>
                        {services.length > 0 ? (
                            services.map((service, key) => (
                                <div key={key}>{service.description}</div>
                            ))
                        ) : (
                            <div>Du hast keine Services gebucht.</div>
                        )}
                    </Columns.Column>
                </Columns>
            </Box>
        </div>
    );
};

export default CustomerData;
