import React from "react";

import Container from "../components/Container";
import CustomerNumberRedeemer from "../components/CustomerNumberRedeemer";

const Activation = () => {
    return (
        <Container>
            <CustomerNumberRedeemer />
        </Container>
    );
};

export default Activation;
