import React, { useEffect } from "react";

import { Router } from "@gatsbyjs/reach-router";
import Helmet from "react-helmet";

import { css } from "@emotion/react";
import shallow from "zustand/shallow";

import Activation from "../../components/pages/Hcm/Activation";
import Admin from "../../components/pages/Hcm/Admin";
import GiveCustomerAdmin from "../../components/pages/Hcm/Admin/GiveCustomerAdmin";
import Navigation from "../../components/pages/Hcm/components/Navigation";
import Dashboard from "../../components/pages/Hcm/Dashboard";
import useStore, { sections } from "../../components/pages/Hcm/Dashboard/store";
import RequestActivation from "../../components/pages/Hcm/RequestActivation";
import DevextremeStyles from "../../components/shared/DevextremeStyles";
import { navigate } from "../../components/shared/LinkComponent";
import ProtectedRoute from "../../components/shared/ProtectedRoute";
import StorageHandler from "../../utils/StorageHandler";

const storage = new StorageHandler();

const HcmPage = ({ location }) => {
    const withFont = css`
        font-family: var(--font-family-vito);
    `;

    const [loading, loadingSpecific, data, fetchData, setSelectedSection] =
        useStore(
            (state) => [
                state.loading,
                state.loadingSpecific,
                state.data,
                state.fetchData,
                state.setSelectedSection,
            ],
            shallow
        );

    useEffect(() => {
        if (data === null && !loading) {
            navigate("/hoyercard-portal/activation");
        } else {
            const startSection = sections.find(
                (section) => section.url === location.pathname
            );
            if (startSection) {
                setSelectedSection(startSection);
            } else {
                navigate(sections[0].url);
                setSelectedSection(sections[0]);
            }
        }
    }, [data, loading]);

    useEffect(() => {
        void fetchData(storage.get("selectedCustomerNumber", undefined));
    }, []);

    return (
        <>
            <Helmet>
                <title>Hoyer Card Portal</title>
            </Helmet>

            <DevextremeStyles />

            <div css={withFont}>
                <Navigation />
                <Router>
                    <Dashboard path="/hoyercard-portal/*" location={location} />
                    {!loading && !loadingSpecific && (
                        <>
                            <Activation path="/hoyercard-portal/activation" />
                            <RequestActivation path="/hoyercard-portal/request-activation" />
                        </>
                    )}
                    <Admin path="/hoyercard-portal/admin" />
                    <GiveCustomerAdmin path="/hoyercard-portal/admin/customer-admin" />
                </Router>
            </div>
        </>
    );
};
const ProtectedHcmPage = (props) => (
    <ProtectedRoute {...props} component={HcmPage} />
);

export default ProtectedHcmPage;
