import React from "react";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import HoyerAclError from "../../../../../shared/HoyerAclError";
import LinkComponent from "../../../../../shared/LinkComponent";
import NewHeadline from "../../../../../shared/NewHeadline";
import Box from "../../../components/Box";

const UnlockCustomerNumbers = () => {
    const { canAny } = useHoyerAcl();

    if (!canAny([Permission.CUSTOMER_ADMIN_LIST])) {
        return <></>;
    }

    return (
        <Box>
            <NewHeadline>Vergabe Kunden-Admin</NewHeadline>
            <p>Kunden Admins verwalten</p>
            <LinkComponent
                color="red"
                button
                link={{
                    text: "Vergeben",
                    href: "/hoyercard-portal/admin/customer-admin",
                }}
            />
        </Box>
    );
};

export default UnlockCustomerNumbers;
