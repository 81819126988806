import React, { useState } from "react";

import { apiFetchProtected } from "../../../../../apiBindings";
import { timestampOlderOrEquals } from "../../../../../utils/DateHelpers";
import StorageHandler from "../../../../../utils/StorageHandler";
import Box from "../../../../shared/Box";
import Columns, { LargeColumn } from "../../../../shared/Columns";
import FormError from "../../../../shared/forms/FormError";
import NewInput from "../../../../shared/forms/NewInput";
import { Link } from "../../../../shared/LinkComponent";
import Button from "../../../../shared/NewForms/Button";
import { UncontrolledNotification as Notification } from "../../../../shared/Notification";
import { Headline } from "../Headline";
import HoyerCardLoadingIndicator from "../HoyerCardLoadingIndicator";
import * as styles from "./style.module.scss";

const storage = new StorageHandler();

const RedeemCodeRequester = () => {
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);
    const [error, setError] = useState(false);
    const [customerNumber, setCustomerNumber] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();

        setLoading(true);
        setError(false);
        const data = await apiFetchProtected("/hoyercard-portal/request-code", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify({
                customerNumber,
            }),
        });

        storage.set("hcm:customer-number-code-request", Date.now());
        setLoading(false);
        if (data.status === "ok") {
            setFinished(true);
        } else {
            setError(true);
        }
    };

    /**
     * Check if the user can send a activation / deactivation request.
     */
    const canSend = () => {
        const lastSent = storage.get(`hcm:customer-number-code-request`);

        return lastSent === null || timestampOlderOrEquals(lastSent, 5);
    };

    return (
        <Columns centered>
            <LargeColumn>
                <Box>
                    {finished || !canSend() ? (
                        <>
                            <Headline>Aktivierungscode angefordert</Headline>
                            <Notification type="success">
                                <p>
                                    Sie haben den Aktivierungscode erfolgreich
                                    angefordert. Wir melden uns in Kürze bei
                                    Ihnen.
                                </p>
                            </Notification>
                        </>
                    ) : (
                        <>
                            <Headline>
                                Fordern Sie einen Aktivierungscode an
                            </Headline>
                            <p>Geben Sie bitte Ihre Kundennummer ein.</p>
                            <form onSubmit={onSubmit}>
                                <NewInput
                                    name="customer_number"
                                    value={customerNumber}
                                    validation="integer"
                                    placeholder="Kundennummer"
                                    onChange={(event) => {
                                        setCustomerNumber(event.target.value);
                                    }}
                                    required
                                />
                                {error && (
                                    <FormError message="Die Daten stimmen nicht überein, bitte überprüfen Sie diese." />
                                )}

                                {loading ? (
                                    <HoyerCardLoadingIndicator>
                                        Aktivierungscode wird angefordert.
                                    </HoyerCardLoadingIndicator>
                                ) : (
                                    <Button color="red" disabled={loading}>
                                        Anfordern
                                    </Button>
                                )}
                            </form>
                        </>
                    )}
                    <div className={styles.noCode}>
                        <span>Sie haben bereits einen Aktivierungscode?</span>
                        <Button
                            color="blue"
                            small
                            text
                            to={"/hoyercard-portal/activation"}
                        >
                            Hier eingeben
                        </Button>
                    </div>
                </Box>
            </LargeColumn>
        </Columns>
    );
};

export default RedeemCodeRequester;
