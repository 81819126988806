import React from "react";

import styled from "@emotion/styled";

import LoadingIndicator from "../../../../shared/LoadingIndicator";

const Wrapper = styled.div`
    margin-bottom: 30px;
`;

const LoadingIndicatorText = styled.div`
    padding-top: 30px;
    padding-bottom: 30px;
`;

const HoyerCardLoadingIndicator = ({ children }) => {
    return (
        <Wrapper>
            <LoadingIndicator>
                <LoadingIndicatorText>{children}</LoadingIndicatorText>
            </LoadingIndicator>
        </Wrapper>
    );
};

export default HoyerCardLoadingIndicator;
