import React, { useEffect } from "react";

import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import Button from "../../../../shared/NewForms/Button";
import useDashboardStore from "../../Dashboard/store";
import OnboardingRequestCard from "./OnboardingRequest/OnboardingRequestCard";
import useStore from "./store";

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-bottom: 20px;
`;

const AppOnboardingRequests = (): React.ReactElement => {
    const [selectedCustomerNumber] = useDashboardStore(
        (state) => [state.selectedCustomerNumber],
        shallow
    );
    const [loading, onboardingRequests, fetchOnboardingRequests] = useStore(
        (state) => [
            state.loading,
            state.onboardingRequests,
            state.fetchOnboardingRequests,
        ],
        shallow
    );

    useEffect(() => {
        if (selectedCustomerNumber !== null) {
            void fetchOnboardingRequests(selectedCustomerNumber);
        }
    }, [selectedCustomerNumber]);

    return (
        <>
            {onboardingRequests.length === 0 && (
                <p>Aktuell liegen keine Anfragen vor</p>
            )}
            {onboardingRequests.length > 0 && (
                <Grid>
                    {onboardingRequests.map((request) => (
                        <OnboardingRequestCard
                            request={request}
                            key={request.id}
                        />
                    ))}
                </Grid>
            )}
            <Button
                color="redOutline"
                centered
                disabled={loading}
                loading={loading}
                onClick={() =>
                    selectedCustomerNumber &&
                    fetchOnboardingRequests(selectedCustomerNumber)
                }
            >
                Aktualisieren
            </Button>
        </>
    );
};

export default AppOnboardingRequests;
