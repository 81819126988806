import React from "react";

import { Permission, useHoyerAcl } from "../../../../../../hooks/useHoyerAcl";
import NewHeadline from "../../../../../shared/NewHeadline";
import Box from "../../../components/Box";
import UnlockForm, { types } from "../components/UnlockForm";

const RemoveCustomerNumber = () => {
    const { can } = useHoyerAcl();
    if (!can(Permission.USER_DISCONNECT_FROM_CUSTOMER)) {
        return null;
    }

    return (
        <Box>
            <NewHeadline>Verknüpfen aufheben</NewHeadline>
            <p>
                Die Verknüpfung der Kundennummer mit der eingegebenen E-Mail
                wird aufgehoben.
            </p>
            <UnlockForm type={types.removeCustomerNumber} />
        </Box>
    );
};

export default RemoveCustomerNumber;
