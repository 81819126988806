import React from "react";

import { Permission, useHoyerAcl } from "../../../../hooks/useHoyerAcl";
import { navigate } from "../../../shared/LinkComponent";
import Container from "../components/Container";
import UnlockCustomerNumbers from "./UnlockCustomerNumbers";

const Admin = () => {
    const { canAny } = useHoyerAcl();

    if (
        !canAny([
            Permission.USER_CONNECT_TO_CUSTOMER,
            Permission.USER_GENERATE_ACTIVATION_CODE,
            Permission.USER_DISCONNECT_FROM_CUSTOMER,
            Permission.CUSTOMER_ADMIN_LIST,
        ])
    ) {
        void navigate("/hoyercard-portal");
    }

    return (
        <Container>
            <UnlockCustomerNumbers
                exact
                path="/hoyercard-portal/admin/unlock-customer-numbers"
            />
        </Container>
    );
};

export default Admin;
