import React from "react";

import { Router } from "@gatsbyjs/reach-router";

import shallow from "zustand/shallow";

import { Permission, useHoyerAcl } from "../../../../../hooks/useHoyerAcl";
import AdBluePriceList from "../../components/AdBluePriceList";
import useStore from "../store";
import Cards from "./Cards";
import CardsCreate from "./CardsCreate";
import HoyerPayRequests from "./HoyerPayRequests";
import HoyerPayVehicles from "./HoyerPayVehicles";
import ServiceModules from "./ServiceModules";
import TransactionInvoices from "./TransactionInvoices";
import TransactionRefueling from "./TransactionRefueling";

const DashboardRouter = ({
    canManageHoyerPay,
}: {
    canManageHoyerPay: boolean;
}) => {
    const { can } = useHoyerAcl();
    const [data, selectedCustomerNumber] = useStore(
        (state) => [state.data, state.selectedCustomerNumber],
        shallow
    );

    return (
        <Router>
            {can(Permission.INVOICE_LIST) && (
                <TransactionInvoices path="invoices" />
            )}

            {can(Permission.TRANSACTION_LIST) && (
                <TransactionRefueling path="refueling" />
            )}

            {can(Permission.CARD_LIST) && <Cards path="cards" />}

            {can(Permission.CARD_CREATE_NEW) && (
                <CardsCreate path="cards/create/" />
            )}

            {canManageHoyerPay && (
                <>
                    <HoyerPayRequests path="hoyer-pay/requests" />
                    <HoyerPayVehicles path="hoyer-pay/vehicles" />
                    {/*<HoyerPayPersons path="hoyer-pay/persons" />*/}
                </>
            )}

            {can(Permission.SERVICE_LIST) && (
                <ServiceModules path="customer-data" />
            )}

            {data &&
                selectedCustomerNumber &&
                data[selectedCustomerNumber].canViewAdBluePriceList && (
                    <AdBluePriceList path="ad-blue" />
                )}
        </Router>
    );
};

export default DashboardRouter;
