/**
 * Format the date into the YYYY-MM-DD format.
 * @param {*} date
 */
export const formatDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    return `${year}-${month < 10 ? 0 : ""}${month}-${day < 10 ? 0 : ""}${day}`;
};

/**
 * Format the date into the DD.MM.YYYY format.
 * @param {*} date
 */
export const toReadableDate = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();

    return `${day < 10 ? 0 : ""}${day}.${month < 10 ? 0 : ""}${month}.${year}`;
};

/**
 * Calculates the date in the given amount of working days.
 */
export const getDateInGivenWorkingDays = (workingDays) => {
    const validFrom = new Date();
    const calculatedDate = new Date();
    const forerun = workingDays;
    let daysToAdd = 0;

    validFrom.setHours(0, 0, 0, 0);
    calculatedDate.setHours(0, 0, 0, 0);

    // Calculate the days, that should be added because of weekends in between.
    for (let i = 1; i < forerun; i++) {
        calculatedDate.setDate(calculatedDate.getDate() + 1);
        if (calculatedDate.getDay() === 0 || calculatedDate.getDay() === 6) {
            daysToAdd++;
        }
    }

    validFrom.setDate(validFrom.getDate() + (forerun + daysToAdd + 1));

    // If first valid wish date is a sunday, add one day.
    if (validFrom.getDay() === 0) {
        validFrom.setDate(validFrom.getDate() + 1);
    }
    // If first valid wish date is a saturday, add two days.
    if (validFrom.getDay() === 6) {
        validFrom.setDate(validFrom.getDate() + 2);
    }

    return validFrom;
};

/**
 * Checks if a timestamp is older or equals than the given number of minutes
 */
export const timestampOlderOrEquals = (timestamp, minutes) => {
    if (timestamp === null) {
        return false;
    }

    return (
        Math.floor((Date.now() - parseInt(timestamp)) / 1000 / 60) >= minutes
    );
};

/**
 * Format the date into the DD.MM.YYYY format.
 * @param {*} dateTime
 * @param {*} skipSeconds
 */
export const toReadableDateTime = (dateTime, skipSeconds = false) => {
    const dateObj = new Date(dateTime);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1;
    const year = dateObj.getFullYear();
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes();
    const seconds = dateObj.getSeconds();

    const helper = (item) => {
        return item < 10 ? `0${item}` : item;
    };

    const withoutSeconds = `${helper(day)}.${
        month < 10 ? 0 : ""
    }${month}.${year}
    ${helper(hours)}:${helper(minutes)}`;

    if (skipSeconds) {
        return withoutSeconds;
    }

    return `${withoutSeconds}:${helper(seconds)}`;
};
