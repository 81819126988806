import React from "react";

import { useForm } from "react-hook-form";

import ReactHookInput from "../../../../../shared/forms/ReactHookInput";

type Inputs = {
    search: string;
};

interface iProps {
    search: string;
    setSearch: (value: string) => void;
}

const Search = ({ search, setSearch }: iProps) => {
    const { control, resetField, watch } = useForm<Inputs>({
        defaultValues: {
            search: search,
        },
        mode: "onSubmit",
    });

    const searchValue = watch("search");

    const handleSearch = () => {
        setSearch(searchValue);
    };

    const handleReset = () => {
        setSearch("");
        resetField("search");
    };

    return (
        <ReactHookInput
            placeholder="Search"
            name="search"
            fullWidth
            rules={{
                maxLength: 50,
            }}
            handleClick={handleSearch}
            resetField={searchValue && handleReset}
            control={control}
        />
    );
};

export default Search;
