import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet";

import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import shallow from "zustand/shallow";

import { errorAwareApiFetchProtected } from "../../../../../apiBindings";
import { Permission, useHoyerAcl } from "../../../../../hooks/useHoyerAcl";
import { IconPredefined } from "../../../../shared/icons/Icon";
import { PredefinedIconType } from "../../../../shared/icons/Icon/Predefined";
import Headline from "../../../../shared/NewHeadline";
import useSnackbar from "../../../../shared/Snackbar/store";
import Container from "../../components/Container";
import Create from "./components/Create";
import Search from "./components/Search";

const Table = styled.table`
    display: block;
    ${({ theme }) =>
        theme.breakpoints.from.small.css`
            display: table;
            width: 100%;
            margin-top: 10px;
            color: ${theme.colors.blue.toString()};
            white-space: nowrap;
            border-collapse: collapse;
    `}
`;

const THead = styled.thead`
    ${({ theme }) =>
        theme.breakpoints.upTo.extraSmall.css`
          display: none;
    `}
`;

const TBody = styled.tbody`
    display: block;
    ${({ theme }) =>
        theme.breakpoints.from.small.css`
            display: table-row-group;
    `}
`;

const Th = styled.th`
    display: block;
    ${({ theme }) =>
        theme.breakpoints.from.small.css`
            display: table-cell;
            padding: 12px 8px;
            color: ${theme.colors.blue.toString()};
    `}
`;

export const Tr = styled.tr`
    ${({ theme }) =>
        theme.breakpoints.upTo.extraSmall.css`
           display: block;
            padding: 18px;
            margin: 10px 0;

            :nth-of-type(even) {
                background-color: ${theme.colors.grey.toString()};
            }
    `}

    ${({ theme }) =>
        theme.breakpoints.from.small.css`
            display: table-row;
            border: 1px solid ${theme.colors.darkGrey.toString()};
    `}
`;

export const Td = styled.td`
    ${({ theme }) =>
        theme.breakpoints.upTo.extraSmall.css`
           display: flex;
           flex-wrap: wrap;
           gap: 5px;
           align-items: center;
           padding-top: 8px;
           padding-bottom: 8px;

           :nth-of-type(1) {
               ::before {
                   font-weight: normal;
                   content: "E-Mail:";
               }
           }

           :nth-of-type(2) {
               ::before {
                   font-weight: normal;
                   content: "Kundennummer:";
               }
           }
    `}

    ${({ theme }) =>
        theme.breakpoints.from.small.css`
            display: table-cell;
            padding: 8px;
            border: 1px solid ${theme.colors.darkGrey.toString()};
    `}
`;

export const ActionTd = styled.td`
    display: flex;
    justify-content: end;

    ${({ theme }) =>
        theme.breakpoints.from.small.css`
           display: table-cell;
           display: flex;
           align-items: center;
           justify-content: space-around;
           padding: 8px 0;
    `}
`;

export const TextButton = styled.button`
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 0;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.red.toString()};
    cursor: pointer;
    background-color: transparent;
    border: none;
`;

const ActionRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;
    margin-top: 10px;
`;

const IconButton = styled(IconPredefined)`
    cursor: pointer;
`;

type iUser = {
    id: number;
    email: string;
};

type iCustomer = {
    id: number;
    user: iUser;
    customer_number: string;
};

type iApiResponse = {
    customers: iCustomer[];
};

type iApiDeleteResponse = {
    message: string;
};

const GiveCustomerAdmin = () => {
    const { can } = useHoyerAcl();
    const theme = useTheme();
    const [customers, setCustomers] = useState<iCustomer[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [isCreating, setIsCreating] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");

    const [confirm, notify] = useSnackbar(
        (state) => [state.confirm, state.notify],
        shallow
    );

    const blur = css`
        filter: blur(3px);
    `;

    useEffect(() => {
        getData();
    }, [search]);

    const getData = () => {
        errorAwareApiFetchProtected<iApiResponse>(
            `/hoyercard-portal/customer-admin?search=${search}`,
            {
                method: "GET",
            }
        )
            .then((response) => {
                setCustomers(response.customers);
            })
            .catch((errors) => {
                console.log(errors);
            })
            .finally(() => setLoading(false));
    };

    const deleteCustomer = async (customer: iCustomer) => {
        const confirmed = await confirm(
            "Admin Berechtigung entziehen?",
            `Möchtest Du die Admin Berechtigung für den Benutzer ${customer.user.email} wirklich entziehen?`
        );

        if (confirmed) {
            handleDelete(customer);
        }
    };

    const handleDelete = (customer: iCustomer) => {
        setLoading(true);
        errorAwareApiFetchProtected<iApiDeleteResponse>(
            `/hoyercard-portal/customer-admin/delete/${customer.id}`,
            {
                method: "DELETE",
            }
        )
            .then((response) => {
                notify("Erfolgreich", response.message);
                getData();
            })
            .finally(() => setLoading(false));
    };

    if (!can(Permission.CUSTOMER_ADMIN_LIST)) {
        return <></>;
    }

    return (
        <>
            <Helmet>
                <title>Admins Hoyer Card Portal</title>
            </Helmet>
            <Container>
                <Headline>Vergabe Kunden-Admin</Headline>
                <Search search={search} setSearch={setSearch} />
                <ActionRow>
                    <TextButton onClick={() => setIsCreating(true)}>
                        Neu verknüpfen{" "}
                        <IconPredefined
                            color={theme.colors.red.toString()}
                            type={PredefinedIconType.plus}
                        />
                    </TextButton>
                </ActionRow>
                {isCreating && (
                    <Create
                        setIsCreating={setIsCreating}
                        isCreating={isCreating}
                        getData={getData}
                    />
                )}
                <Table css={loading && blur}>
                    <THead>
                        <tr>
                            <Th>E-Mail</Th>
                            <Th>Kundennummer</Th>
                            <Th></Th>
                        </tr>
                    </THead>
                    <TBody>
                        {customers &&
                            customers.map((customer) => {
                                return (
                                    <Tr key={customer.id}>
                                        <Td>{customer.user.email}</Td>
                                        <Td>{customer.customer_number}</Td>
                                        <ActionTd>
                                            {Permission.CUSTOMER_ADMIN_DELETE && (
                                                <IconButton
                                                    type={
                                                        PredefinedIconType.dustbin
                                                    }
                                                    color={theme.colors.red.toString()}
                                                    onClick={() =>
                                                        deleteCustomer(customer)
                                                    }
                                                />
                                            )}
                                        </ActionTd>
                                    </Tr>
                                );
                            })}
                        {(!customers || customers.length <= 0) && (
                            <Tr>
                                <td>Keine Daten vorhanden</td>
                            </Tr>
                        )}
                    </TBody>
                </Table>
            </Container>
        </>
    );
};

export default GiveCustomerAdmin;
