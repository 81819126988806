import React from "react";

import shallow from "zustand/shallow";

import CustomerData from "../../components/CustomerData";
import useStore from "../store";

const ServiceModules = ({ ...props }) => {
    const [data, selectedCustomerNumber] = useStore(
        (state) => [state.data, state.selectedCustomerNumber],
        shallow
    );

    return (
        data &&
        selectedCustomerNumber && (
            <CustomerData
                data={data[selectedCustomerNumber].customerData}
                services={data[selectedCustomerNumber].services}
            />
        )
    );
};

export default ServiceModules;
