import React from "react";

import { useLocation } from "@gatsbyjs/reach-router";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import LinkComponent from "../../../../shared/LinkComponent";

const Container = styled.div`
    margin-top: 20px;
    ${({ theme }) => css`
        ${theme.paddings.containerOuter.left}
        ${theme.paddings.containerOuter.right}

        color: ${theme.colors.blue.toString()};
    `}
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 60px;
`;

const Navigation = () => {
    const location = useLocation();

    const showBackLink = location.pathname.endsWith("/hoyercard-portal/admin");

    return (
        showBackLink && (
            <Container>
                <NavContainer>
                    <LinkComponent
                        link={{ href: "/hoyercard-portal", text: "zurück" }}
                        icon="arrow-left"
                        leftIcon
                    />
                </NavContainer>
            </Container>
        )
    );
};

export default Navigation;
