import React from "react";

import styled from "@emotion/styled";

import { Permission, useHoyerAcl } from "../../../../../hooks/useHoyerAcl";
import HoyerAclError from "../../../../shared/HoyerAclError";
import GivCustomerAdminLink from "../GiveCustomerAdmin/components/Link";
import GenerateCode from "./GenerateCode";
import RemoveCustomerNumber from "./RemoveCustomerNumber";
import UnlockDirectly from "./UnlockDirectly";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 20px;
`;

const UnlockCustomerNumbers = () => {
    const { canAny } = useHoyerAcl();

    if (
        !canAny([
            Permission.USER_CONNECT_TO_CUSTOMER,
            Permission.USER_GENERATE_ACTIVATION_CODE,
            Permission.USER_DISCONNECT_FROM_CUSTOMER,
            Permission.CUSTOMER_ADMIN_LIST,
        ])
    ) {
        return (
            <HoyerAclError message="Du hast keine Berechtigung den Admin-Bereich zu benutzen." />
        );
    }

    return (
        <>
            <Grid>
                <UnlockDirectly />
                <GenerateCode />
                <RemoveCustomerNumber />
            </Grid>
            <GivCustomerAdminLink />
        </>
    );
};

export default UnlockCustomerNumbers;
